/**
 * @description Make cols from given array.
 * @param {T[]} array
 * @param {number} groups
 * @returns {T[][]}
 */
export default function makeCols<T>(array: T[], groups: number): T[][] {
  const result: T[][] = Array.from({ length: groups }, () => []);

  array.forEach((item, index) => {
    result[index % groups].push(item);
  });

  return result;
}
